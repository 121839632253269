import getCDNURL from "../utils/getCDNURL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/pro-light-svg-icons";
import { Application, SwitcherOptionsInterface } from "../utils/nav.config";
import { useEffect, useRef, useState } from "react";

interface HeaderLogoProps {
  application: Application;
  cdnUrl: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  switcherOptions: SwitcherOptionsInterface[];
  headerHeight: number;
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({ application, cdnUrl, isOpen, setIsOpen, switcherOptions, headerHeight }) => {
  const [leftOffset, setLeftOffset] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const isTournament = application === 'tournament';
  const isTeamLeagues = application === 'team-leagues';
  const isWPRPage = application === 'wpr-rankings'

  const src = isTournament
    ? getCDNURL(`${cdnUrl}/pickleball-app/header-logos/PT_Abreviated.svg`, 45, 28)
    : isTeamLeagues
      ? getCDNURL(`${cdnUrl}/pickleballteamleagues-app/ptl-logo.svg`, 65, 40)
      : isWPRPage
        ? getCDNURL(`${cdnUrl}/pickleball-app/header-logos/WPR_Abreviated.svg`, 64, 44)
        : getCDNURL(`${cdnUrl}/pickleball-app/assets/images/logo.svg`, 32, 23);

  const width = isTournament ? 45 : isTeamLeagues ? 64 : isWPRPage ? 45 : 32;
  const height = isTournament ? 28 : isTeamLeagues ? 44 : isWPRPage ? 20 : 23;
  const alt = isTournament ? "Pickleballtournaments.com" : "Pickleball.com";

  const selectedOption = switcherOptions.find(({ app }) => app === application);
  const otherOptions = switcherOptions.filter(({ app }) => app !== application);

  const lastElement = otherOptions.length - 1;

  const dropdownHeight = otherOptions.length * 56

  const skewHeightOffset = screenWidth >= 640 ? 24 + (56 - height)/2 - Math.tan((12 * Math.PI) / 180) * (24 + (56 - height)/2) : 31

  useEffect(() => {
    const screenHeight = document.documentElement.clientHeight || otherOptions.length * 56
    const screenWidth = document.documentElement.clientWidth
    setScreenHeight(document.documentElement.clientHeight || otherOptions.length * 56);
    setScreenWidth(document.documentElement.clientWidth);
    if (dropdownRef.current) {
      const dropdownWidth = dropdownRef.current.offsetWidth;

      const numItems = otherOptions.length + 1;
      const skewOffset = screenWidth < 640 ? Math.tan((12 * Math.PI) / 180) * Math.min(((numItems + 1) * 56), screenHeight - headerHeight - 20) : Math.tan((12 * Math.PI) / 180) * (numItems * 56);
      if (screenWidth < 640) {
        const centerPosition = Math.max((screenWidth - dropdownWidth) / 2, 0);
        setLeftOffset(centerPosition + skewOffset / 2);
      } else {
        setLeftOffset(12 + skewOffset / 2);
      }

      if (isOpen && screenWidth < 640) {
        const scrollY = window.scrollY;
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.top = `-${scrollY}px`;
        document.body.style.width = "100%";
      } else {
        document.body.style.overflow = "";
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.width = "";
      }
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };

  }, [isOpen]);

  return (
    <div className="relative">
      <div className="flex-row items-center cursor-pointer flex" onClick={toggleDropdown}>
        <div className="hidden sm:flex !size-6 items-center justify-center">
          <FontAwesomeIcon icon={isOpen ? faClose : faBars} size="lg" />
        </div>
        <h1 className="flex sm:hidden w-[65px] items-center justify-center">
          {!isOpen ? <img src={src} width={width} height={height} alt={alt} className="z-40 skew-x-12" /> : <FontAwesomeIcon icon={faClose} height={height} size="lg" />}
        </h1>
        <h1 className="hidden !w-16 sm:flex justify-center items-center">
          <img src={src} width={width} height={height} alt={alt} className="z-40 skew-x-12" />
        </h1>
      </div>
      {isOpen && (
        <>
          <div
            className="absolute skew-x-12 left-[17px] h-[30px] w-[343px] cursor-default"
            style={{
              marginTop: `${skewHeightOffset - 18}px`,
            }}
            onMouseEnter={() => setIsOpen(true)}
          />
          <div
            ref={dropdownRef}
            className="absolute bg-black min-w-[300px] min-[350px]:min-w-[343px] max-w-[343px] !w-full w-grow text-white rounded-2xl shadow-lg skew-x-12 overflow-y-auto"
            style={{
              marginTop: `${skewHeightOffset}px`,
              left: `${leftOffset}px`,
              maxHeight: `${screenWidth < 640 ? screenHeight - headerHeight - 20 : dropdownHeight}px`,
              overflowY: screenWidth < 640 ? 'auto' : 'visible'
            }}
          >
            {selectedOption && (
              <a key={selectedOption.label} href={selectedOption.link} className="flex items-center max-h-14 pr-2 bg-gray-900 hover:cursor-pointer rounded-t-2xl">
                <div className={`${selectedOption.bgDark} flex flex-row rounded-tl-2xl rounded-bl-2xl`}>
                  <div className={`!w-[112px] !min-w-[112px] h-14 flex items-center rounded-br-2xl justify-center ${selectedOption.bg} rounded-tl-2xl`}
                    style={{
                      clipPath: "polygon(100% 0%, 90% 90%, 89.5% 91.5%, 89% 93%, 88% 94.5%, 87% 96%, 86.5% 97.5%, 85% 98.8%, 83% 99.5%, 81% 99.8%, 78% 100%, 0% 100%, 0% 0%)",
                    }}
                  >
                    <img
                      src={getCDNURL(`${cdnUrl}/${selectedOption.img}`, 32, 32)}
                      alt={selectedOption.label}
                      className="h-[30px] mr-[-4px]"
                    />
                  </div>
                </div>
                <span className="ml-2 text-lg font-medium">{selectedOption.label}</span>
              </a>
            )}
            {otherOptions.map(({ label, img, bg, bgDark, link }, index) => (
              <a key={label} href={link} className={`flex items-center max-h-14 pr-2 bg-gray-900 hover:cursor-pointer ${index === lastElement ? 'rounded-b-2xl' : ''}`}>
                <div className={`${bgDark} flex flex-row rounded-tl-2xl rounded-bl-2xl`}>
                  <div
                    className={`!w-[112px] !min-w-[112px] h-14 flex items-center justify-center ${bg} ${index === lastElement ? "rounded-bl-2xl" : ""
                      }`}
                    style={{
                      clipPath: "polygon(100% 0%, 90% 90%, 89.5% 91.5%, 89% 93%, 88% 94.5%, 87% 96%, 86.5% 97.5%, 85% 98.8%, 83% 99.5%, 81% 99.8%, 78% 100%, 0% 100%, 0% 0%)",
                    }}
                  >
                    <img
                      src={getCDNURL(`${cdnUrl}/${img}`, 32, 32)}
                      alt={label}
                      className="h-[30px] mr-[-4px]"
                    />
                  </div>
                </div>
                <span className="ml-2 text-lg font-medium">{label}</span>
              </a>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderLogo;
